// Some custom styling for making a form assembly embed look decent
.wFormContainer {
 .htmlSection {
    @apply my-5 md:my-5-lg;
  }

  .wFormTitle {
    @apply heading-xl;
  }

  .section legend {
    @apply heading-lg;
  }

  label.reqMark:after {
    @apply content-['*'] text-coral-b ml-[3px];
  }

  .hint {
    @apply block text-secondary;
  }

  .hintsBelow {
    .hint,
    .errMsg {
      @apply mt-[12px];
    }
  }

  .hintsAbove {
    .hint,
    .errMsg {
      @apply mb-[12px];
    }
  }

  .choices {
    @apply flex flex-col;
  }

  .oneChoice {
    @apply inline-flex items-center;

    label {
      @apply font-normal;
    } 
  }

  div > div.oneField {
    @apply mt-4;
  }

  .supportInfo {
    @apply mt-2 body-xs;
  }

  // save and resume
  .saveAndResume > .oneField {
    @apply body-xs flex items-center;

    label {
      @apply mb-0;
    }

    & > * {
      @apply mx-2;

      &:first-child {
        @apply ml-0;
      }

      &:last-child {
        @apply mr-0;
      }
    }
  }

  #saveAndResumeFieldset {
    @apply hidden;
    @apply my-3 py-3 pl-4 border-l-8 border-l-blue-50-tint md:my-3-lg md:py-3-lg md:pl-4-lg;
  }

  // input fields
  .inputWrapper {
    position: relative;

    .oneChoice {
      @apply items-start mb-2;

      &:first-child {
        @apply mt-3;
      }

      input[type=checkbox], 
      input[type=radio] {
        + label {
          @apply mt-2;

          p {
            @apply mb-0;
          }
        }
      }
    }
  }

  .offstate, 
  .wfHideSubmit {
    display: none !important;
  }

  // Pagination
  .wfPage {
    @apply hidden;
  }

  .actions,
  .wfPagingButtons {
    @apply mt-sm;
  }

  // If the actions container follows a page that is not active, then don't show it
  .wfPage + .actions {
    @apply hidden;
  }

  // Hidden field class
  .wf-acl-hidden {
    @apply hidden;
  }

  // page section
  .section {
    @apply p-0 m-0;
  }

  // other unnecessary spaces
  div + br, 
  label + br {
    @apply hidden;
  }

  ul, ol {
    @apply last:mb-4 md:last:mb-4-lg;
  }
}

// date picker field

.kalendae {
	display: inline-block;
  zoom:1;
	background:white;
	padding:1rem;
	margin:5px;
  border: 2px solid theme('colors.blue.75-tint');
	border-radius:2px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	cursor:default;
	position:relative;

  .k-title,
  .k-header,
  .k-days {
    display:block;
    overflow:hidden;
    width:100%;
    height:auto;
    margin:0;
  }
}

/** Popup Container for Kalendae.Input **/
.kalendae.k-floating {
	position:absolute;
	top:0;
	left:0;
	z-index:100000;
	margin:0;
	box-shadow:0 1px 3px rgba(0,0,0,0.75);
	-moz-box-shadow:0 1px 3px rgba(0,0,0,0.75);
	-webkit-box-shadow:0 1px 3px rgba(0,0,0,0.75);
}

/** Kalendae.Input's popup close button **/
.kalendae .k-btn-close {
	position:absolute;
	top:-0.6em;
	right:-0.6em;
	width:1.2em;
	height:1.2em;
	background:white;
	line-height:1;
	text-align:center;
	border-radius:1.2em;
	cursor:pointer;
	text-decoration:none;
  color:theme('colors.blue.DEFAULT');
}
.kalendae .k-btn-close:after {
  content:"\2716";
}
.kalendae .k-btn-close:hover {
	background:white;
}

/** Month Container **/
.kalendae .k-calendar {
  display: inline-block;
  zoom:1;
  width:14em;
  vertical-align:top;
}

/** Month Title Row **/
.kalendae .k-title {
  text-align:center;
  white-space:nowrap;
  position:relative;
}

/** Month and Year Buttons **/
.kalendae .k-btn-previous-month,
.kalendae .k-btn-next-month,
.kalendae .k-btn-previous-year,
.kalendae .k-btn-next-year {
	width:1.6em;
	height:auto;
	cursor:pointer;
	position:absolute;
	font-size:1.4em;
	line-height:1;
	text-decoration:none;
  color:theme('colors.blue.DEFAULT');
}

.kalendae .k-btn-previous-year {left:0;}
.kalendae .k-btn-previous-month {left:16px;}
.kalendae .k-btn-next-month {right:16px;}
.kalendae .k-btn-next-year {right:0;}

.kalendae .k-btn-previous-month:after {content:"\2039";}
.kalendae .k-btn-next-month:after {content:"\203A";}

.kalendae .k-btn-previous-year:after {content:"\00AB";}
.kalendae .k-btn-next-year:after {content:"\00BB";}

/** Remove extra buttons when calendar shows multiple months **/
.kalendae .k-first-month .k-btn-next-month,
.kalendae .k-middle-month .k-btn-next-month,
.kalendae .k-middle-month .k-btn-previous-month,
.kalendae .k-last-month .k-btn-previous-month,
.kalendae .k-first-month .k-btn-next-year,
.kalendae .k-middle-month .k-btn-next-year,
.kalendae .k-middle-month .k-btn-previous-year,
.kalendae .k-last-month .k-btn-previous-year {display:none;}

/** Disable year nav option **/
.kalendae .k-title.k-disable-year-nav .k-btn-next-year,
.kalendae .k-title.k-disable-year-nav .k-btn-previous-year { display: none; }
.kalendae .k-title.k-disable-year-nav .k-btn-next-month { right: 0; }
.kalendae .k-title.k-disable-year-nav .k-btn-previous-month { left: 0; }

/** Hide unusable buttons **/
.kalendae.k-disable-next-month-btn .k-btn-next-month,
.kalendae.k-disable-previous-month-btn .k-btn-previous-month,
.kalendae.k-disable-next-year-btn .k-btn-next-year,
.kalendae.k-disable-previous-year-btn .k-btn-previous-year {
	display:none;
}

/** Week columns and day cells **/
.kalendae .k-header span,
.kalendae .k-days span {
	float:left;
  width:calc(2em - 2px);
  box-sizing:border-box;
  height:inherit;
  text-align:center;
  padding:2px;
}

.kalendae .k-header span {
	font-weight:bold;
  margin:5px auto;
}

.kalendae .k-days span {
  margin:1px;
	line-height:1;
	border-radius:3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

/** Today **/
.kalendae .k-today {
	text-decoration:underline;
}

/** Days inside of the month view **/
.kalendae .k-days span.k-in-month.k-active {
	background-color:theme('colors.coral-b.25-tint');
}
/** Days outside of the month view (before the first day of the month, after the last day of the month) **/
.kalendae .k-days span.k-out-of-month {
  color:theme('colors.blue.75-tint');
}

/** Selectable  **/
.kalendae .k-days span.k-active {
	cursor:pointer;
  
  &:hover {
    background:theme('colors.coral-b.DEFAULT');
    color:white;
  }
}

/** Selected day, when outside the selectable area **/
.kalendae .k-days span.k-selected {
	border-color:theme('colors.blue.DEFAULT');
}

/** Selected day, when inside the selectable area **/
.kalendae .k-days span.k-selected.k-active {
	background:theme('colors.coral-b.DEFAULT');
	color:white;
}

/** Days between the start and end points on a range, outside of the selectable area **/
.kalendae .k-days span.k-range {
	border-color:theme('colors.blue.DEFAULT');
}

/** Days between the start and end points on a range, inside of the selectable area **/
.kalendae .k-days span.k-range.k-in-month {
	background-color:theme('colors.coral-b.25-tint');
}

/** Selectable week, hovered **/
.kalendae .k-week:hover span.k-active {
	background:theme('colors.coral-b.DEFAULT');
	color:white;
}

.clearfix:after { 
  visibility: hidden; 
  display: block; 
  font-size: 0; 
  content: " "; 
  clear: both; 
  height: 0; 
}

// autosuggest field

.fa-search::before {
  display: block;
  content: '';
  background-repeat: no-repeat;
  background-image: url("/dist/images/icons/magnifying-glass.svg");
  background-size: auto 75%;
  background-position: left center;
  width: 2.5rem;
  height: 3rem;
}

.wForm div .typeahead,
.wForm div .tt-query,
.wForm div .tt-hint {
  width: auto;
  min-width: 164px;
  height: 30px;
  padding: 8px 12px;
  font-size: inherit;
  line-height: inherit;
  outline: none;
}

.wForm div .typeahead {
  background-color: white;
}

.wForm div .tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.wForm div .tt-hint {
  margin-top: 12px;
  color: theme('colors.blue.75-tint');
}

.wForm div .tt-menu {
  box-sizing: border-box;
  width: 100%;
  min-width: 164px;
  margin: 0px;
  padding: 0px;
  background-color: white;
  max-height: calc(2em * 5.5);
  overflow-y: auto;
  border: 2px solid theme('colors.blue.75-tint');
}

.wForm div .tt-suggestion {
  margin: 0;
  padding: 6px;
}

.wForm div .tt-suggestion:hover {
  cursor: pointer;
  background-color:theme('colors.coral-b.25-tint');
}

.wForm div .tt-suggestion.tt-cursor {
  background-color:theme('colors.coral-b.25-tint');
}

.wForm div .tt-empty-message {
	font-style: italic;
  margin: 0;
  padding: 0 6px;
}

.wForm div .tt-more-message {
	font-size: 0.7em;
	padding: 0 6px;
	border-top: 1px solid theme('colors.blue.75-tint');
}

.wForm .tt-spinner {
  position: absolute;
  display: none;
  top:.6rem;
  right:.25rem
}
.wForm .tt-caret-down {
	position: absolute;
	top: 8px;
	right: 8px;
	cursor: pointer;
}
.wForm .tt-search {
	position: absolute;
	top: 28%;
	left: 7px;
	z-index: 5;
	cursor: pointer;
}

.wForm .tt-clear {
	position: absolute;
	top: 28%;
	right: 3%;
	z-index: 5;
	cursor: pointer;
}
.wForm .tt-clear.no-input {
	visibility: hidden;
}

.wFormContainer .wForm .inputWrapper input[type="text"][data-dataset-json],
.wFormContainer .wForm .inputWrapper input[type="text"][data-dataset-id] {
	padding-left: 3rem;
	padding-right: 2.5rem;
}